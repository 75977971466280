var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",staticClass:"container",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.signup($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('h2',[_vm._v("Créer ton compte")])]),_c('v-row',{attrs:{"justify":"center"}},[_c('validation-provider',{attrs:{"name":"Pseudo","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Pseudo","placeholder":"Fait en sorte d'être reconnaissable","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","placeholder":"you@gmail.com","required":"","autocomplete":"username"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('ValidationProvider',{attrs:{"name":"Mot de passe","rules":"required|min:8|verify_password|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mot de passe","type":"password","placeholder":"Genre '1234' tqt (je plaisante)","autocomplete":"current-password","error-messages":errors,"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('ValidationProvider',{attrs:{"name":"Confirmation de mot de passe","vid":"confirm","rules":"required|min:8|verify_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"Confirmation mot de passe","placeholder":"Genre tu remets '1234' (lol)","autocomplete":"current-password","error-messages":errors,"required":""},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})]}}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mb-8",attrs:{"loading":_vm.signupLoading,"color":"primary","disabled":invalid || _vm.signupLoading,"type":"submit"}},[_vm._v(" Créé mon compte ! ")])],1),_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center"}},[_vm._v(" ou ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{on:{"click":function($event){return _vm.$emit('toggleForm')}}},[_vm._v("Revenir à la connexion")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }